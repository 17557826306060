import React, { FC, useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '@components/layout'
import SEO from '@components/seo'
import MastHead from '@components/MastHead'
import StoriesComponent from '@components/Stories'

import useImageRatio from '@hooks/useImageRatio'

interface T {
  imageSlugs
  Quote
  height
  article
  data: {
    mdx: {
      frontmatter: {} | any
    }
    site: {}
  }
}

const frontmatterInit = {
  title: '',
  video: {
    youtubeId: '',
    description: '',
  },
  images: {
    landscape: '',
    portrait: '',
    overlay: false,
  },
  author: {
    name: '',
  },
}

type TVideo = {
  author: {}
  youtubeId: string
  description: string
}

const VideoComponent: FC<TVideo> = ({ author, youtubeId, description }) => (
  <>
    <div className="video">
      <div className="video-wrapper">
        <iframe
          title={`Een video over ${author.name}`}
          src={`https://www.youtube.com/embed/${youtubeId}?rel=0&amp;disablekb=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
    <div className="subline padding">
      <p>{description}</p>
    </div>
  </>
)

const BlogTemplate: FC<T> = ({ imageSlugs, quote, height, article, data }) => {
  const [frontmatter, setFrontmatter] = useState<any>(frontmatterInit)
  useEffect(() => {
    setFrontmatter(data.mdx.frontmatter)
    console.log(frontmatter)
  }, [data])
  const ratio = useImageRatio()
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout className="article" title={siteTitle}>
      <a className="skip-main" href="#article">
        Direct naar content
      </a>

      <MastHead
        title={frontmatter.title}
        article={frontmatter.images.landscape}
        quote={frontmatter.title}
        overlay={frontmatter.images.overlay}
      />
      <SEO
        title={frontmatter.title}
        imageUrl={frontmatter.images.landscape}
        description={frontmatter.description || post.excerpt}
      />

      <Link className="back article__back" to="/">
        Terug naar homepage
      </Link>
      <section id="article" className="padding">
        <div className="article__head">
          <blockquote className="non-blockquote">
            <h1 className="article__title h2">
              {frontmatter.title_secondary}
              {/* {post.frontmatter.title} */}
            </h1>
          </blockquote>
        </div>

        <div className="article__body">
          <MDXRenderer>{post.body}</MDXRenderer>

          {frontmatter.video &&
          frontmatter.video.description &&
          frontmatter.video.youtubeId ? (
            <VideoComponent
              description={frontmatter.video.description}
              author={frontmatter.author}
              youtubeId={frontmatter.video.youtubeId}
            />
          ) : (
            ''
          )}
        </div>
      </section>

      <section>
        <h2 className="padding">
          Meer verhalen uit <span className="het_onderwijs">het onderwijs</span>
        </h2>
        <div className="no-padding">
          <StoriesComponent />
        </div>
      </section>

      <section>
        <div className="padding">
          <span className="slogan h2">
            <span className="werken">Werken in het onderwijs.</span> De baan van
            het leven.
          </span>
        </div>
      </section>
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        images {
          portrait
          landscape
          overlay
        }
        author {
          name
        }
        title
        title_secondary
        # date(formatString: "MMMM DD, YYYY")
        description
        video {
          description
          youtubeId
        }
      }
    }
  }
`
