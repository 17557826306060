import React, { useEffect, useState } from 'react'
import useWindowSize from '@hooks/useWindowSize'

const useImageRatio = () => {
  const [ratio, setRatio] = useState<'portrait' | 'landscape'>('landscape')
  const [width] = useWindowSize()

  useEffect(() => {
    const ratio = width < 768 ? 'portrait' : 'landscape'
    setRatio(ratio)
  }, [width])
  return ratio
}

export default useImageRatio
